import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Platform,Dimensions} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { StatusBar } from "expo-status-bar";
import style from './style';
import Menu from '../menu';
import api from '../config/api_urls';
import ReactMarkdown from 'react-markdown'
import AsyncStorage from '@react-native-community/async-storage';
import {PlayvisitEmbed} from '../joc2/PlayvisitEmbed';

export default class Pagina6 extends React.Component{

    constructor(props){
        super(props);
        this.state = props.route.params;
        if (typeof this.state.idioma === 'undefined') {
          this.state['idioma'] = '';
      } 
    }

    componentDidMount(){
    //  console.log("entra Pag 6");
      this.getIdioma();
    }

    getIdioma = async () => {
      try{
        const value = await AsyncStorage.getItem('idioma');
        if(value !== null){
        //  console.log(value);
          this.setState({idioma: value});
        }
      }catch(e){
        console.log(e);
      }
    }

plataforma(){
  return(  this.state.projecte.pagines.map((dades) => {
     if(dades.Nom.toLowerCase()  === this.state.projecte.BotoMenu6Url.toLowerCase()){
       if(dades.Tipus == "Tipus1"){
      //   console.log("here");
      //   console.log(this.state.idioma);
        return(
          <View style={ this.state.projecte.MidaMaximaPagines ? null :{marginTop: this.state.pop_up_margin_top , maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.15}}>
            <View style={style.caixaOut}>
              <View style={[style.caixa,this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null , { borderColor: this.state.projecte.ColorBorderContenidor} ]}>
                <ScrollView> 
                  <View style={{flex:1, justifyContent:'center',}}>
                    <Image style={{height: this.state.pop_up_height * 0.17, resizeMode: 'contain', paddingTop: 5}} source={{ uri: api.api_url + dades.Imatge.url }} />
                      <Text style={[style.titol,{color:this.state.projecte.ColorCorporatiuText1}]}>{dades['Nom'+this.state.idioma]}</Text>
                    <View style={{justifyContent:'center', paddingLeft: 30, paddingRight: 30,color: this.state.projecte.ColorCorporatiuText2,}}>
                      <ReactMarkdown  style={style.text}>{dades['Tipus1Text'+this.state.idioma]}</ReactMarkdown>
                    </View>     
                  </View>
                </ScrollView>
              </View>
            </View>
          </View>
        )
      }else if(dades.Tipus == "Tipus2"){ //Mostrar ifame Joc2
         return (
          <View style={{ flex: 1,backgroundColor: "#fff"}}>
            <StatusBar style="auto" />
            <PlayvisitEmbed link={dades.Tipus2Url} />     
          </View>
      );
      }else if(dades.Tipus == "Tipus3"){
        return(
          <View style={style.container}>
            <iframe src={dades.LinkTipus3} style={{ height:(Dimensions.get('window').height/100)*90, width:'100%'}} />
          </View>
        )
      }
     }
    }))
    
  }
    render(){
        return(   
          <View style={{ flex: 1 }}>
             <View style={{ flex: 15}}>
              <ImageBackground source={this.state.projecte.FonsPantalla4 ?{ uri: api.api_url + this.state.projecte.FonsPantalla4.url } : null} style={style.imageBackground}>
                {this.plataforma()}  
              </ImageBackground>
              </View>
          <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
            <Menu projecte={this.state}></Menu>
          </View>
          </View>
        )
      }
        
    }