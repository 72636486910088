import * as React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity, ScrollView,Dimensions} from 'react-native';
import style from './style';
import api from '../config/api_urls';


export default class VideoPantallaComplerta extends React.Component{

 constructor(props) {
    super(props);
    this.state = props.route.params;
  }

  componentDidMount(){
    console.log('====================================');
    console.log(this.state.premiTrucada);
    console.log('====================================');
    if(!this.state.premiTrucada.EsUnVideo){
    document.getElementById('audioTrucada').play()
    }
  }

    render(){
    
        return(

<View style={{flex:1}}>
                {this.state.premiTrucada.EsUnVideo ?(
                    <View style={{ height:'100%', width:'100%',backgroundColor:'black',alignSelf:'center',justifyContent:'center'}}>
                        <div id="container">
                        <div id="audioTrucada"></div>
                        <video id="video" autoPlay  style={{ height:'100%', width:'100%', resizeMode: 'cover', }}>
                        {/* <source  src={require('../../img/video.mp4')} type="video/mp4"  /> */}
                            <source src={{uri:this.state.premiTrucada.LinkVideo}} type="video/mp4"  />
                        </video>
                    </div>
                        </View>
            )
            :
            (
                <View style={{flex:1}}>
                    {console.log(this.state.premiTrucada)}
                    <ImageBackground source={{uri: api.api_url+this.state.premiTrucada.imatge_trucada.url}} style={{ flex: 1, resizeMode: "cover", justifyContent: "center" }} >
                    </ImageBackground>
                    <audio id="audioTrucada" src={require('../../img/olerdola.mp3')}  preload></audio>
                </View>
            )
            }
                <View style={{  flex:0.05, bottom:70,left:0,right:0, position:'fixed', }}>
                    <TouchableOpacity onPress={()=>{ 
                        this.state.projecte.EsUnVideoElLink ?    
                            document.getElementById('container').remove('video') :
                            document.getElementById('audioTrucada').remove('audioTrucada'); this.props.navigation.navigate('Home', this.state);}}>
                        <Image source={require('../../img/closeCall.png')} style={{ height: this.state.pop_up_height * 0.2, width: this.state.pop_up_height * 0.3,resizeMode: 'contain', alignSelf:'center' }} />
                    </TouchableOpacity>
                </View>
            </View>
            )
        }
    }

//style={{ height:(Dimensions.get('window').height/100)*90, width:'100%'}}


// <audio id="audioTrucada" controls  autoPlay preload style={{visibility:'hidden', width:1,height:1}}>
//                         {/* <source src={require('../../img/AudioTrucada.mp3')} type="audio/ogg"/> */}
//                         <source src={require('../../img/olerdola.mp3')} type="audio/ogg"/>
//                     </audio>