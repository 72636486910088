import React, { Component } from "react";
import { StyleSheet, Text, TouchableHighlight, View,ImageBackground,Dimensions,Image,TouchableOpacity } from "react-native";
import Modal from "modal-react-native-web";
import style from './style';
import api from '../config/api_urls';
import { Video, AVPlaybackStatus } from 'expo-av';
import AsyncStorage from "@react-native-community/async-storage";

export default class Telefon extends React.Component{

 constructor(props) {
    super(props);
    this.state = props.route.params;
  }

valuetext(value) {
  return (value);
}
componentDidMount(){
  document.getElementById('audio').play()
  AsyncStorage.removeItem('lastPoiTrucada') 
}



render() {

    return (
      
      <View style={{flex:1}}>
         <ImageBackground source={ this.state.projecte.ImatgeTrucadaInicial ?{ uri: api.api_url + this.state.projecte.ImatgeTrucadaInicial.url } : null} style={{ flex: 1, resizeMode: "cover", justifyContent: "center" }} >
        <View style={{flex:0.7}}>
        </View>
        <View style={{flex:0.3 , flexDirection:'row', alignSelf:'center' }}>
            <TouchableOpacity onPress={() =>{document.getElementById('audio').remove('audio'), this.props.navigation.navigate('Home', this.state);}}  >
                  <Image source={require('../../img/closeCall.png')} style={{ height: this.state.pop_up_height * 0.2, width: this.state.pop_up_height * 0.2,resizeMode: 'contain', alignSelf:'center', right:'70%' }} />            
            </TouchableOpacity>
            <TouchableOpacity onPress={() =>{document.getElementById('audio').remove('audio'), this.props.navigation.navigate('Video', this.state);}}  >
                  <Image source={require('../../img/mobile.gif')} style={{ height: this.state.pop_up_height * 0.2, width: this.state.pop_up_height * 0.2,resizeMode: 'contain', alignSelf:'center',left:'70%' }} />            
            </TouchableOpacity>
        </View>
        <audio id="audio" src={require('../../img/call.mp3')} loop preload></audio>
         </ImageBackground>

      </View>
    )
  }
}