import { StatusBar } from 'expo-status-bar';
import React, { Component } from 'react';
import { Text, ImageBackground, View, Image, Alert, TouchableOpacity, Platform, Dimensions } from 'react-native';
import api from './components/config/api_urls';
import * as Font from 'expo-font';
import * as RNLocalize from "react-native-localize";
import ReactMarkdown from 'react-markdown';
import * as WebBrowser from 'expo-web-browser';
import { setAnalyticsCollectionEnabled } from 'expo-firebase-analytics';

//Navegacio
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
//import Proves from './components/proves/proves';
import Home from './components/home/home';
import Prova from './components/proves/proves';
import Inici from './components/inici/inici';
import Telefon from './components/telefon/telefon'
import Login from './components/login/login';
import Perfil from './components/perfil/perfil';
import Joc1 from './components/joc1/joc1';
import Joc2 from './components/joc2/joc2';
import Joc3 from './components/joc3/joc3'; 
import Joc3Completa from './components/joc3/joc3PantallaComplerta';
import Credits from './components/credits/credits';
import Rondalla from './components/laRondalla/rondalla';
import Pagina6  from './components/laRondalla/pagina6';
import AsyncStorage from '@react-native-community/async-storage';
import VideoPantallaComplerta from './components/video/video';
import MenuPosicionament from './components/menuClio/menuClio';

//Style caixeti
import style from './components/credits/style';

//ordre 0
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = { loadedData: false, fontLoaded: false };
    this.changed = false;
    this.state['veDePerfil'] = true
    this.state['pop_up_height'] = Dimensions.get('screen').height * (13 / 15) * 0.67;
    this.state['pop_up_margin_top'] = Dimensions.get('screen').height * (13 / 15) * 0.22;

  }

  componentDidMount() {
     setAnalyticsCollectionEnabled(true)
    let locales = RNLocalize.getLocales();
    if (Array.isArray(locales)) {
      //Agafa el primer idioma del telefon
      locales = locales[0].languageTag;
    }
   // console.log(locales);
    console.log(locales.substring(0,2));
    switch(locales.substring(0,2)) {
      case 'es':
        AsyncStorage.setItem('idioma','_ES');
        this.setState({idioma: '_ES'});
        break;
      case 'en':
        AsyncStorage.setItem('idioma','_EN');
        this.setState({idioma: '_EN'});
        break;
      case 'fr':
        AsyncStorage.setItem('idioma','_FR');
        this.setState({idioma: '_FR'});
        break;
      default:
        AsyncStorage.setItem('idioma','');
        this.setState({idioma: ''});
      break;
    }
    Font.loadAsync({
      'Lobster': require('./assets/fonts/Formata.otf'), // Vestigis
       //'Lobster': require('./assets/fonts/Lobster.ttf'), // Met De Ribes
      'OpenSans': require('./assets/fonts/Open_Sans/OpenSans-Regular.ttf')
    });
    this.getData()
    this.getMostarCredits() 
  }

  getLastPoiTrucada = async () => {
    try {
      const value = await AsyncStorage.getItem('lastPoiTrucada');
    //  console.log(value);
        if (value !== null) {
         this.state.premiTrucada = JSON.parse(value);
         this.props.navigation.navigate('Telefon', this.state);
        }else{
          this.setState({loadedData: true});
        }
    }catch (e) {
        console.log(e);
    }
  }

  getMostarCredits = async () => {
    try {
      const value = await AsyncStorage.getItem('anarCredits');
    //  console.log(value);
      if (value !== null) {
        if(value == "false" || value == false){
          this.setState({veDePerfil : false })
        }
      
        
      }  
    } catch (e) {
      console.log(e);
      
    }
  }

  getData() {
    return fetch(api.vestigis,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log("Data obtained...");
        console.log(responseJson);
        this.setState(
          {
            itemId: 856,
            projecte: responseJson[0],
            urlImatgeInicial: (responseJson[0]['ImatgeInicial'+this.state.idioma] != null) ? api.api_url + responseJson[0]['ImatgeInicial'+this.state.idioma].url : "",
          },
          function () { this.getLastPoiTrucada()}
        );

        if (this.state.urlImatgeInicial == "")  this.changeScreen();

      })
      .catch(error => {
        console.error(error);
      });
  }

  changeScreen() {
    if (!this.changed){
      if(!this.state.veDePerfil){
        AsyncStorage.setItem('anarCredits',true);
        this.props.navigation.navigate('Home', this.state);
        this.changed = true;
      }else{
      //  this.props.navigation.navigate('Telefon', this.state); 
       this.props.navigation.navigate('Inici', this.state);
       this.changed = true;
      }
      height:(Dimensions.get('window').height/100)*80
      }
  }


  mostrarPaginaPrincipal(){
    if(this.state.projecte.MostrarPantallaLogos && this.state.veDePerfil){
      if(this.state.projecte.MostrarTextExplicatiu){
        return(
           
              <View style={{ flex: 1, alignContent:'center', alignSelf:'center' }}>
                <Image source={this.state.projecte.ImatgePrincipalTextExplicatiu ?{ uri: api.api_url + this.state.projecte.ImatgePrincipalTextExplicatiu.url } : null} style={{ height: this.state.pop_up_height * 0.6, width: this.state.pop_up_height * 0.6,resizeMode: 'contain', alignSelf:'center' }} />
                <View style={{flexDirection:'row', alignSelf:'center', paddingTop:15}}>
                  <Text style={{ fontFamily: 'Lobster', fontSize: 21, textAlign: "center",color: "#006fb7", fontSize: 25, paddingBottom:20 }}>
                    {this.state.projecte['TextExplicatiu'+this.state.idioma]}
                  </Text>
                  <TouchableOpacity onPress={() =>{this._handlePressButtonAsync(this.state.projecte.UrlTextExplicatiu ? this.state.projecte.UrlTextExplicatiu : null )}}>
                    <Image source={this.state.projecte.ImatgeBotoTextExplicatiu ?{ uri: api.api_url + this.state.projecte.ImatgeBotoTextExplicatiu.url } : null} style={{ height: this.state.pop_up_height * 0.2, width: this.state.pop_up_height * 0.2,resizeMode: 'contain', alignSelf:'center' }} />
                  </TouchableOpacity>
                </View>
              </View>
          
        )
      }else{
      if(this.state.projecte.MostrarCaixeti){
        return(
        <View style={{ flex: 1,}}>
           <View style={[style.caixaOut, {backgroundColor: this.state.projecte.colorPrincipal2}]}>
              <View style={[style.caixa,this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null , { borderColor: this.state.projecte.ColorBorderContenidor } ]}>
                <Text style={{ fontFamily: 'Lobster', fontSize: 21, textAlign: "center", paddingTop: 5, paddingBottom: 10, color: "white", fontSize: 28 }}>
                  {this.state.projecte['TitolPantallaLogos'+this.state.idioma]}
                </Text>
                <Image style={{ height: this.state.pop_up_height * 0.8, resizeMode: 'contain' }} source={{ uri: this.state.urlImatgeInicial }} />
              </View>
            </View>
          </View>
          )
      }else{
        return (
               <View style={{ alignContent: 'center' }}>
                 <Image style={{ height: this.state.pop_up_height * 0.95, resizeMode: 'contain' }} source={{ uri: this.state.urlImatgeInicial }} />
               </View>
        )
      }
    }
    }else{
      this.changeScreen();
    }
  }
  
    _handlePressButtonAsync = async (link) => {
      let result = await WebBrowser.openBrowserAsync(link);
    };

  render() {
    if (this.state.loadedData) {
        return (
          <View style={{ flex: 1 }}>
            <TouchableOpacity style={{flex:1}}  onPress={() =>{ this.changeScreen();}}>
              <ImageBackground source={this.state.projecte.FonsPantallaLogos ? { uri: api.api_url + this.state.projecte.FonsPantallaLogos.url } : null} style={{ flex: 1, resizeMode: "cover", justifyContent: "center" }} >
                <View style={{ marginTop: this.state.pop_up_margin_top * 0.5, height: (this.state.pop_up_height * 1.5) }}>     
                  {
                   this.mostrarPaginaPrincipal()
                  }
                </View>
              </ImageBackground>
            </TouchableOpacity>
          </View>
        )   
    } else {
      return (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <TouchableOpacity>
            <Image style={{ alignSelf: 'center', width: 150, height: 150, marginTop: 10 }} source={require('./img/loading.gif')} />
          </TouchableOpacity>
        </View>
      )
    }
  }
}
///////////////////////* NAVIGATION PART *///////////////////////////////

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const App = () => (
  <NavigationContainer>
    <Stack.Navigator screenOptions={{
      headerShown: false,
    }} >
      <Stack.Screen name="Main" Proves component={Main}  />
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen name="Inici" component={Inici} />
      <Stack.Screen name="Rondalla" component={Rondalla} />
      <Stack.Screen name="Pagina6" component={Pagina6} />
      {/* <Stack.Screen name="Home" component={MenuPosicionament}/> */}
      <Stack.Screen name="Home" component={Home} />
      <Stack.Screen name="Joc1" component={Joc1} />
      <Stack.Screen name="Joc2" component={Joc2} />
      <Stack.Screen name="Joc3" component={Joc3} />
      <Stack.Screen name="Video" component={VideoPantallaComplerta} />
      <Stack.Screen name="Joc3Completa" component={Joc3Completa} />
      <Stack.Screen name="Perfil" component={Perfil} />
      <Stack.Screen name="Credits" component={Credits} />
      <Stack.Screen name="Telefon" component={Telefon} />
    </Stack.Navigator>
  </NavigationContainer>
)

export default App;